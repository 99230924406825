(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-game/assets/javascripts/helpers/map-state-to-game.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-game/assets/javascripts/helpers/map-state-to-game.js');
"use strict";

const _excluded = ["selectedDraw", "selectedProduct"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }

const mapStateToGame = (groupId, createGame, draws) => {
  const {
      selectedDraw,
      selectedProduct
    } = createGame,
    formData = _objectWithoutProperties(createGame, _excluded);
  formData.buyStopDiff = formData.buyStopDiff || 30;
  const draw = draws.draws[selectedProduct].find(draw => draw.drawNumber === selectedDraw);
  const drawCloseTime = new Date(draw.regCloseTime);
  const buyStop = new Date(drawCloseTime.getTime() - 60 * formData.buyStopDiff * 1000);
  return _objectSpread(_objectSpread({}, formData), {}, {
    buyStop,
    drawCloseTime,
    fractionPrice: parseInt(formData.fractionPrice, 10),
    productName: selectedProduct,
    productId: draw.productId,
    drawNumber: selectedDraw,
    isSubmitted: false,
    isFinished: false,
    maxFractionsPerMember: formData.maxFractionsPerMember,
    groupId,
    isFavorite: false,
    name: createGame.name
  });
};
setGlobal('svs.components.marketplaceData.createGame.helpers.mapStateToGame', mapStateToGame);

 })(window);